import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from "../../assets/logo/latest-logo.png";
import { NavLink } from "react-router-dom";
import "./Header.scss";

const Header = () => {
  const [scroll, setScroll] = useState(0);
  const [isActive, setActive] = useState(false);

  const clickMenuBar = () => {
    setActive(!isActive);
  };

  const closeMenu = () => {
    setActive(false);
  }
  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY > 100;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    });
  });

  return (
    <Navbar
      expand="lg"
      className={`header position-fixed w-100 py-3 ${scroll ? "nav-scroll bg-transparent shadow-none" : ""
        }`}
    >
      <Container >
        <Navbar.Brand href="#">
          <NavLink to="/" className="text-decoration-none">
            {/* <strong className="logo-txt">Simplefix IT</strong> */}
            <img src={logo} alt="banner-img" className="img-fluid d-lg-block d-none" />
          </NavLink>
        </Navbar.Brand>
        {/* <Navbar.Toggle aria-controls="navbarScroll" /> */}
        <div className={`nav-toggle ${isActive ? "toggleOpen" : ""}`}>
          <span
            className="bar d-block position-relative"
            onClick={clickMenuBar}
          ></span>
        </div>
        <Navbar.Collapse
          id="navbarScroll"
          className={`${isActive ? "menuOpen" : ""}`}
        >
          <Nav className="ms-auto my-2 my-lg-0" navbarScroll>
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive
                  ? "active position-relative nav-top-link text-decoration-none"
                  : " position-relative nav-top-link text-decoration-none"
              }
              onClick={closeMenu}
            >
              Home
            </NavLink >

            <div className="nav-top-link dropdown">
              <NavLink
                to="/services"
                className={({ isActive }) =>
                  isActive
                    ? "active position-relative nav-top-link text-decoration-none"
                    : " position-relative nav-top-link text-decoration-none"
                }
                onClick={closeMenu}
              >
                Services
              </NavLink>
              <div className="dropdown-menu p-0">
                <NavLink
                  to="/web-development"
                  className="text-decoration-none"
                 onClick={closeMenu}>
                  Web App Development
                </NavLink>
                <NavLink
                  to="/mobile-development"
                  className="text-decoration-none"
                >
                  Mobile App Development
                </NavLink>
                <NavLink
                  to="/ui-ux-devlopment"
                  className="text-decoration-none"
                >
                  UI UX Development
                </NavLink>

                <NavLink
                  to="/hire-developers"
                  className="text-decoration-none"
                >
                  Hire Developers
                </NavLink>
                <NavLink
                  to="/digital-marketing"
                  className="text-decoration-none"
                >
                  Digital Marketing
                </NavLink>
                <NavLink
                  to="/on-demand-solutions"
                  className="text-decoration-none"
                >
                  On Demand Solution
                </NavLink>
              </div>
            </div>

            <NavLink
              to="/about"
              className={({ isActive }) =>
                isActive
                  ? "active position-relative nav-top-link text-decoration-none"
                  : " position-relative nav-top-link text-decoration-none"
              }
              onClick={closeMenu}
            >
              About US
            </NavLink>

            <NavLink
              to="/contact"
              className={({ isActive }) =>
                isActive
                  ? "active me-0 position-relative nav-top-link text-decoration-none"
                  : "me-0 position-relative nav-top-link text-decoration-none"
              }
              onClick={closeMenu}
            >
              Contact Us
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
