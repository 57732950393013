import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import mobileui from "../../assets/img/services/mobile-app-development.jpg";
import ondemand from "../../assets/img/services/on-demand.png";
import webdevimg from "../../assets/img/services/web-app.jpg";
import uiuximg from "../../assets/uiuximg.png";
import hireimg from "../../assets/img/services/hire-developers2.png";
import digitalmarketing from "../../assets/img/digital-marketing.png";
import { Link } from "react-router-dom";
import "../../App.scss";

const Services = () => {
  return (
    <>
      <section className="service-banner">
        <Container>
          <Row>
            <Col sm={12}>
              <h1 className="fw-bold mb-3 text-center">
                Our <span className="blue-color">Services</span>
              </h1>
              <p className="mb-0 text-center">
                We offer a variety of services to cater to the technological
                needs of businesses, organizations, and individuals. These
                services are often customized based on the specific needs and
                goals of clients, allowing businesses to leverage technology for
                efficiency, innovation, and growth. For us, it's important to
                assess your needs or requirements and ensure that the chosen
                requirements aligns with your business objectives.
              </p>
              {/* <p className="mb-0"> Below are some common services provided by us:</p> */}
            </Col>
          </Row>
        </Container>
      </section>
      <section className="sec-pd services bg-grey">
        <Container>
          <Row>
            <Col sm={12}>
            <div className="sec-header mb-4">
              <h2 className="fw-bold text-center btm-border position-relative">Services We Provide</h2>
              </div>
            </Col>
            <Col md={6} lg={4} className="mb-4 h-100">
              <Card className="border-0 shadow h-100">
                <Card.Img
                  variant="top"
                  className="h-100 w-100"
                  src={webdevimg}
                />
                <Card.Body className="p-4 service-content">
                  <Card.Title>Web App Development</Card.Title>
                  <Card.Text>
                    Provide software applications that are accessed through web
                    browsers.
                  </Card.Text>
                  <Link
                    className="text-decoration-none text-white rounded blue-btn d-inline-block blue-btn-sm blue-bg text-decoration-none text-white mt-2 border-0"
                    to="/web-development">{"View More"}
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} lg={4} className="mb-4 h-100">
              <Card className="border-0 shadow h-100">
                <Card.Img
                  variant="top"
                  className="h-100 w-100"
                  src={mobileui}
                />
                <Card.Body className="p-4 service-content">
                  <Card.Title>Mobile App Development</Card.Title>
                  <Card.Text>
                    Offer software applications specifically designed to run on
                    mobile devices, such as smartphones and tablets.
                  </Card.Text>
                  <Link
                    className="text-decoration-none text-white rounded blue-btn d-inline-block blue-btn-sm blue-bg text-decoration-none text-white mt-2 border-0"
                    to="/mobile-development"
                  >{"View More"}
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} lg={4} className="mb-4 h-100">
              <Card className="border-0 shadow h-100"
              >
                <Card.Img variant="top" className="h-100 w-100" src={uiuximg} />
                <Card.Body className="p-4 service-content">
                  <Card.Title>UI UX Development</Card.Title>
                  <Card.Text>
                    Provide aspect of creating software applications, that are
                    visually appealing, user-friendly, and provide an optimal
                    user experience.
                  </Card.Text>
                  <Link
                    className="text-decoration-none text-white rounded blue-btn d-inline-block blue-btn-sm blue-bg text-decoration-none text-white mt-2 border-0"
                    to="/ui-ux-devlopment"
                  >{"View More"}
                  </Link>
                </Card.Body>
              </Card>
            </Col>

            <Col md={6} lg={4} className="mb-4 h-100">
              <Card className="border-0 shadow h-100"
              >
                <Card.Img variant="top" className="h-100 w-100" src={hireimg} />
                <Card.Body className="p-4 service-content">
                  <Card.Title>Hire Developers</Card.Title>
                  <Card.Text>
                    Ensure you find the right candidates with the skills and
                    expertise that match your project requirements.
                  </Card.Text>
                  <Link
                    className="text-decoration-none text-white rounded blue-btn d-inline-block blue-btn-sm blue-bg text-decoration-none text-white mt-2 border-0"
                    to="/hire-developers"
                  >{"View More"}
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} lg={4} className="mb-4 h-100">
              <Card className="border-0 shadow h-100"
              >
                <Card.Img
                  variant="top"
                  className="h-100 w-100"
                  src={digitalmarketing}
                />
                <Card.Body className="p-4 service-content">
                  <Card.Title>Digital Marketing</Card.Title>
                  <Card.Text>
                    Aimed at promoting products, services, or brands through
                    digital channels.
                  </Card.Text>
                  <Link
                    className="text-decoration-none text-white rounded blue-btn d-inline-block blue-btn-sm blue-bg text-decoration-none text-white mt-2 border-0"
                    to="/digital-marketing"
                  >{"View More"}
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} lg={4} className="mb-4 h-100">
              <Card className="border-0 shadow h-100"
              >
                <Card.Img
                  variant="top"
                  className="h-100 w-100"
                  src={ondemand}
                />
                <Card.Body className="p-4 service-content">
                  <Card.Title>On Demand Solutions</Card.Title>
                  <Card.Text>
                    Services or products delivered to users immediately or with
                    minimal delay, specifically on their need and demands
                  </Card.Text>
                  <Link
                    className="text-decoration-none text-white rounded blue-btn d-inline-block blue-btn-sm blue-bg text-decoration-none text-white mt-2 border-0"
                    to="/on-demand-solutions"
                  >{"View More"}
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Services;
